import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const HeadingF = ({ children, fontSize }) => {
  const { data } = useContext(PageContext);
  return (
    <div>
      <h2
        className="font-bold uppercase tracking-wide mb-1"
        style={{
          color: data.metadata.colors.primary,
          fontSize: fontSizeOptions['text-h2'] + fontSize,
        }}
      >
        {children}
      </h2>
      <hr className="mb-3" style={{ borderColor: data.metadata.colors.text }} />
    </div>
  );
};

export default memo(HeadingF);
