import React from 'react';
import PageContext from '../../../contexts/PageContext';
import ContactG from './blocks/Contact/ContactG';
import HeadingG from './blocks/Heading/HeadingG';
import CertificationsC from './blocks/Certifications/CertificationsC';
import EducationC from './blocks/Education/EducationC';
import SkillsD from './blocks/Skills/SkillsD';
import WorkC from './blocks/Work/WorkC';
import ObjectiveC from './blocks/Objective/ObjectiveC';
import ProjectsC from './blocks/Projects/ProjectsC';
import VolunteerC from './blocks/Volunteer/VolunteerC';
import LanguagesF from './blocks/Languages/LanguagesF';
import AwardsD from './blocks/Awards/AwardsD';
import HobbiesD from './blocks/Hobbies/HobbiesD';

import defaultLayouts from '../../../data/defaultLayouts';
import fontSizeOptions from '../../../constants/fontSizeOptions';

const Blocks = {
  summary: ObjectiveC,
  work: WorkC,
  education: EducationC,
  certifications: CertificationsC,
  skills: SkillsD,
  projects: ProjectsC,
  volunteer: VolunteerC,
  languages: LanguagesF,
  awards: AwardsD,
  hobbies: HobbiesD,
};

const ZetyBanking = ({ data }) => {
  const layout = data.metadata.layout.zetybanking
    ? data.metadata.layout.zetybanking
    : defaultLayouts.zetybanking;

  return (
    <PageContext.Provider value={{ data, heading: HeadingG }}>
      <div
        id="page"
        className="px-8 py-2"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          lineHeight: data.metadata.margins.lineHeight,
          fontSize: `${data.metadata.margins.fontSize}px`,
          paddingLeft: `${data.metadata.margins.lrMargins}mm`,
          paddingRight: `${data.metadata.margins.lrMargins}mm`,
          minHeight: '297mm',
        }}
      >
        <div className="grid grid-cols-12 gap-8">
          <div
            className={`${
              data.profile.photograph !== '' ? 'col-span-8' : 'col-span-12'
            }`}
          >
            <div
              className="rounded flex flex-col justify-center"
              style={{
                color: data.metadata.colors.text,
              }}
            >
              <div className="flex flex-col justify-center">
                <h1
                  className="font-bold leading-tight"
                  style={{
                    color: data.metadata.colors.primary,
                    fontSize:
                      fontSizeOptions['text-h1'] +
                      10 +
                      data.metadata.margins.fontSize,
                  }}
                >
                  {data.profile.firstName} {data.profile.lastName}
                </h1>
                <div
                  className="tracking-wide mb-4"
                  style={{
                    fontSize:
                      fontSizeOptions['text-h2'] +
                      data.metadata.margins.fontSize,
                  }}
                >
                  {data.profile.subtitle}
                </div>
                <ContactG />
              </div>
            </div>
          </div>
          {data.profile.photograph && (
            <div className="self-center col-span-4">
              <img
                className="w-32 h-32 rounded-full mx-auto object-cover"
                src={data.profile.photograph}
                alt={data.profile.firstName}
              />
            </div>
          )}
        </div>

        {layout[0] &&
          layout[0].map((x) => {
            const Component = Blocks[x];
            return Component && <Component key={x} />;
          })}
      </div>
    </PageContext.Provider>
  );
};

export default ZetyBanking;
