import React from 'react';
import styles from '../ZetyManagement.module.css';

export const LeftHeading = ({ Heading, fontSize, children, margin }) => {
  return (
    <div
      className={styles.leftHeading}
      style={{ marginLeft: `-${margin}mm`, paddingLeft: `${margin}mm` }}
    >
      <Heading fontSize={fontSize}>{children}</Heading>
    </div>
  );
};

export const RightHeading = ({ Heading, fontSize, children, color }) => {
  return (
    <div style={{ color }}>
      <hr />
      <Heading fontSize={fontSize}>{children}</Heading>
      <hr />
    </div>
  );
};
