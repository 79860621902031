import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const WorkItem = ({ item, language, fontSize }) => {
  const { t } = useTranslation();
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });

  return (
    <div>
      <div className="flex flex-row">
        <div className="w-1/4">
          {item.startDate && (
            <h6
              className="font-medium"
              style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
            >
              {formatDateRangeMMMYYYY(
                {
                  startDate: item.startDate,
                  endDate: item.endDate,
                  language,
                },
                t,
              )}
            </h6>
          )}
        </div>
        <div className="w-3/4 flex flex-col text-left mr-2">
          <h6
            className="font-semibold"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {item.position}
          </h6>
          <span
            className="italic"
            style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
          >
            {item.website ? (
              <a href={item.website}>{item.company}</a>
            ) : (
              item.company
            )}
          </span>
          {item.summary && (
            <ReactMarkdown
              className={`markdown mt-2 ${classes.root}`}
              source={item.summary}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const WorkC = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.work) ? (
    <div>
      <Heading fontSize={data.metadata.margins.fontSize}>
        {data.work.heading}
      </Heading>
      <div className="grid gap-4">
        {data.work.items.map((x) => (
          <WorkItem
            key={x.id}
            item={x}
            language={data.metadata.language}
            fontSize={data.metadata.margins.fontSize}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(WorkC);
