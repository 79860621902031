import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import ResumeTimelineItem from '../../containers/ResumeTimelineItem';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const WorkItem = ({ item, fontSize }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h3
            className="font-semibold"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {item.website ? (
              <a href={item.website}>{item.company}</a>
            ) : (
              item.company
            )}{' '}
          </h3>
          <h4 style={{ fontSize: fontSizeOptions['text-h4'] + fontSize }}>
            {item.position}
          </h4>
        </div>
      </div>
      {item.summary && (
        <ReactMarkdown
          className={`markdown mt-2 ${classes.root}`}
          source={item.summary}
        />
      )}
    </div>
  );
};

const WorkDate = ({ item, language, fontSize }) => {
  const { t } = useTranslation();
  return (
    <div>
      {item.startDate && (
        <h6
          className="font-medium mb-1"
          style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
        >
          {formatDateRangeMMMYYYY(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
        </h6>
      )}
    </div>
  );
};

const WorkE = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const colour = data.metadata.colors.primary;
  const { fontSize, lrMargins } = data.metadata.margins;
  return safetyCheck(data.work) ? (
    <>
      <Heading fontSize={data.metadata.margins.fontSize} iconId="work">
        {data.work.heading}
      </Heading>
      {data.work.items.map((x) => (
        <ResumeTimelineItem
          key={x.id}
          leftContent={
            <WorkDate
              key={x.id}
              item={x}
              language={data.metadata.language}
              fontSize={fontSize}
            />
          }
          colour={colour}
          margin={lrMargins}
        >
          <WorkItem key={x.id} item={x} fontSize={fontSize} />
        </ResumeTimelineItem>
      ))}
    </>
  ) : null;
};

export default memo(WorkE);
