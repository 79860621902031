import React, { memo, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const ProjectItem = ({ item, language, fontSize }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });

  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h3
            className="font-semibold"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {item.website ? (
              <a href={item.website}>{item.title}</a>
            ) : (
              item.title
            )}
          </h3>
        </div>
        {item.startDate && (
          <h3
            className="mb-1"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {formatDateRangeMMMYYYY(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h3>
        )}
      </div>
      {item.summary && (
        <ReactMarkdown
          className={`markdown mt-2 ${classes.root}`}
          source={item.summary}
        />
      )}
    </div>
  );
};

const ProjectsA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.projects) ? (
    <div>
      <Heading fontSize={data.metadata.margins.fontSize}>
        {data.projects.heading}
      </Heading>
      <div className="grid gap-4">
        {data.projects.items.map((x) => (
          <ProjectItem
            key={x.id}
            item={x}
            language={data.metadata.language}
            fontSize={data.metadata.margins.fontSize}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(ProjectsA);
