import React, { memo, useContext, useEffect, useState } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const HobbiesB = () => {
  const { data } = useContext(PageContext);
  const [hobbies, setHobbies] = useState([]);
  const { fontSize } = data.metadata.margins;

  useEffect(() => {
    const temp = [];
    if (!safetyCheck(data.hobbies)) {
      return;
    }
    for (const item of data.hobbies.items) {
      temp.push(item.name);
    }
    setHobbies(temp);
  }, [data.hobbies.items]);

  return safetyCheck(data.hobbies) ? (
    <div className="flex flex-row items-start mb-1">
      <div
        className="flex col-span font-bold mr-2"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {`${data.hobbies.heading}: `}
      </div>
      <div
        className="flex col-span-7"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {hobbies.join(', ')}
      </div>
    </div>
  ) : null;
};

export default memo(HobbiesB);
