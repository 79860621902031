import React, { useEffect, useRef } from 'react';
import PageContext from '../../../contexts/PageContext';
import makeDivPageHeight from '../../../utils/makeDivPageHeight';
import ContactI from './blocks/Contact/ContactI';
import HeadingI from './blocks/Heading/HeadingI';
import ObjectiveE from './blocks/Objective/ObjectiveE';
import WorkE from './blocks/Work/WorkE';
import EducationE from './blocks/Education/EducationE';
import ProjectsB from './blocks/Projects/ProjectsB';
import AwardsC from './blocks/Awards/AwardsC';
import CertificationsD from './blocks/Certifications/CertificationsD';
import SkillsF from './blocks/Skills/SkillsF';
import HobbiesC from './blocks/Hobbies/HobbiesC';
import LanguagesE from './blocks/Languages/LanguagesE';
import styles from './ZetyDataAnalyst.module.css';
import fontSizeOptions from '../../../constants/fontSizeOptions';
import VolunteerE from './blocks/Volunteer/VolunteerE';

const Blocks = {
  summary: ObjectiveE,
  work: WorkE,
  education: EducationE,
  projects: ProjectsB,
  awards: AwardsC,
  certifications: CertificationsD,
  skills: SkillsF,
  hobbies: HobbiesC,
  languages: LanguagesE,
  volunteer: VolunteerE,
};

const ZetyDataAnalyst = ({ data }) => {
  const layout = data.metadata.layout.zetydataanalyst;
  const { fontSize } = data.metadata.margins;
  const pageRef = useRef(null);

  useEffect(() => {
    makeDivPageHeight(pageRef);
  }, [pageRef]);

  const Profile = () => (
    <div>
      <h1
        className="font-bold leading-tight"
        style={{
          color: data.metadata.colors.primary,
          fontSize: fontSizeOptions['text-h1'] + fontSize,
        }}
      >
        {data.profile.firstName} {data.profile.lastName}
      </h1>
      <div
        className="font-medium tracking-wide"
        style={{
          color: data.metadata.colors.primary,
          fontSize: fontSizeOptions['text-h2'] + fontSize,
        }}
      >
        {data.profile.subtitle}
      </div>
    </div>
  );

  return (
    <PageContext.Provider value={{ data, heading: HeadingI }}>
      <div
        id="page"
        className={`${'relative rounded'} ${styles.zetyData}`}
        ref={pageRef}
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          lineHeight: data.metadata.margins.lineHeight,
          fontSize: `${data.metadata.margins.fontSize}px`,
        }}
      >
        <div
          className={styles.leftSection}
          style={{
            backgroundColor: data.metadata.colors.primary,
            paddingLeft: `${data.metadata.margins.lrMargins}mm`,
          }}
        />
        <div
          className={styles.content}
          style={{
            paddingTop: `${data.metadata.margins.tbMargins}mm`,
            paddingBottom: `${data.metadata.margins.tbMargins}mm`,
          }}
        >
          <div className="flex flex-row">
            <div
              className="w-1/5 p-4 z-0 box-content"
              style={{
                paddingLeft: `${data.metadata.margins.lrMargins}mm`,
              }}
            >
              {data.profile.photograph && (
                <img
                  className="rounded object-cover m-auto"
                  src={data.profile.photograph}
                  alt="Resume Photograph"
                  style={{ width: '100px', height: '100px' }}
                />
              )}
            </div>
            <div className="w-4/5 p-4">
              <Profile />
              <ContactI />
              <ObjectiveE />
            </div>
          </div>

          <div>
            {layout[0] &&
              layout[0].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default ZetyDataAnalyst;
