import React, { memo, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import { LeftHeading } from '../../containers/HeadingModifiers';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const CertificationItem = ({ item, language, fontSize, key, t }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });
  return (
    <div key={key} className="mb-3">
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h6
            className="font-semibold"
            style={{
              fontSize: fontSizeOptions['text-base'] + fontSize,
            }}
          >
            {item.title}
          </h6>
          <span
            className="text-xs"
            style={{
              fontSize: fontSizeOptions['text-base'] + fontSize,
            }}
          >
            {`${item.issuer} ${
              item.date &&
              `(${formatDateMMMYYYY({ date: item.date, language }, t)})`
            }`}
          </span>
        </div>
      </div>
      {item.summary && (
        <ReactMarkdown
          className={`markdown mt-1 ${classes.root}`}
          source={item.summary}
        />
      )}
    </div>
  );
};

const CertificationsE = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize, lrMargins } = data.metadata.margins;
  const { t } = useTranslation();

  return safetyCheck(data.certifications) ? (
    <div>
      <LeftHeading Heading={Heading} fontSize={fontSize} margin={lrMargins}>
        {data.certifications.heading}
      </LeftHeading>
      <div className="grid gap-4 m-4">
        {data.certifications.items.map((x) => (
          <CertificationItem
            key={x.id}
            item={x}
            language={data.metadata.language}
            fontSize={fontSize}
            t={t}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(CertificationsE);
