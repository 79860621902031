import React, { memo, useContext, useState, useEffect } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { itemCheck, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const LanguagesC = () => {
  const { data } = useContext(PageContext);
  const [languages, setLanguages] = useState([]);
  const { fontSize } = data.metadata.margins;

  useEffect(() => {
    const temp = [];
    if (!safetyCheck(data.languages)) {
      return;
    }
    for (const item of data.languages.items) {
      if (itemCheck(item.fluency)) {
        temp.push(`${item.name} (${item.fluency})`);
      } else {
        temp.push(item.name);
      }
    }
    setLanguages(temp);
  }, [data.languages.items]);

  return safetyCheck(data.languages) ? (
    <div className="flex flex-row items-start mb-1">
      <div
        className="flex col-span font-bold mr-2"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {`${data.languages.heading}:`}
      </div>
      <div
        className="flex col-span-7"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {languages.join(', ')}
      </div>
    </div>
  ) : null;
};

export default memo(LanguagesC);
