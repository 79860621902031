import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import PageContext from '../../../../../contexts/PageContext';
import ResumeTimelineItem from '../../containers/ResumeTimelineItem';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const EducationItem = ({ item, fontSize }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h3
            className="font-semibold"
            style={{
              fontSize: fontSizeOptions['text-h3'] + fontSize,
            }}
          >
            {item.institution}
          </h3>
          <h4
            style={{
              fontSize: fontSizeOptions['text-h4'] + fontSize,
            }}
          >
            {item.degree} {item.field} {item.gpa ? `, GPA: ${item.gpa}` : ''}
          </h4>
        </div>
      </div>
      {item.summary && (
        <ReactMarkdown
          className={`markdown mt-2 ${classes.root}`}
          source={item.summary}
        />
      )}
    </div>
  );
};

const EducationDate = ({ item, language, fontSize }) => {
  const { t } = useTranslation();
  return (
    <div>
      {item.startDate && (
        <h6
          className="font-medium mb-1"
          style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
        >
          {formatDateRangeMMMYYYY(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
        </h6>
      )}
    </div>
  );
};

const EducationE = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const colour = data.metadata.colors.primary;
  const educationDate = (x) => (
    <EducationDate
      key={x.id}
      item={x}
      language={data.metadata.language}
      fontSize={data.metadata.margins.fontSize}
    />
  );
  return safetyCheck(data.education) ? (
    <>
      <Heading fontSize={data.metadata.margins.fontSize} iconId="education">
        {data.education.heading}
      </Heading>
      {data.education.items.map((x) => (
        <ResumeTimelineItem
          key={x.id}
          leftContent={educationDate(x)}
          colour={colour}
          margin={data.metadata.margins.lrMargins}
        >
          <EducationItem
            key={x.id}
            item={x}
            fontSize={data.metadata.margins.fontSize}
          />
        </ResumeTimelineItem>
      ))}
    </>
  ) : null;
};

export default memo(EducationE);
