import React, { memo, useContext } from 'react';
import { MdPhone, MdEmail } from 'react-icons/md';
import { FaLinkedinIn, FaMousePointer, FaAddressCard } from 'react-icons/fa';
import PageContext from '../../../../../contexts/PageContext';
import { addressCheck } from '../../../../../utils';

const ContactItem = ({ value, link, icon }) => {
  const { data } = useContext(PageContext);
  const { fontSize } = data.metadata.margins;
  return value ? (
    <div className="flex flex-col ">
      <div className="grid grid-cols-10">
        <div className="col-span-1 self-start mt-1" style={{ fontSize }}>
          {icon}
        </div>
        <div className="col-span-9">
          {link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              <span className="font-medium break-all" style={{ fontSize }}>
                {value}
              </span>
            </a>
          ) : (
            <span className="font-medium break-all" style={{ fontSize }}>
              {value}
            </span>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

const ContactI = () => {
  const { data } = useContext(PageContext);

  return (
    <div>
      <div className="relative grid grid-cols-2 gap-3 pt-3 w-3/5 text-xs">
        <ContactItem value={data.contact.phone} icon={<MdPhone />} />
        <ContactItem
          value={data.contact.website}
          link={data.contact.website}
          icon={<FaMousePointer />}
        />
        <ContactItem value={data.contact.email} icon={<MdEmail />} />
        <ContactItem
          value={data.contact.linkedIn}
          link={data.contact.linkedIn}
          icon={<FaLinkedinIn />}
        />

        {data.contact.address && addressCheck(data.contact.address) && (
          <ContactItem
            value={
              <div>
                <p>{data.contact.address.line1}</p>
                <p>{data.contact.address.line2}</p>
                <p>
                  {`${data.contact.address.city} ${data.contact.address.pincode}`}
                </p>
              </div>
            }
            icon={<FaAddressCard />}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ContactI);
