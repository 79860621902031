import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import ResumeTimelineItem from '../../containers/ResumeTimelineItem';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const SkillItem = ({ item, fontSize }) => (
  <div key={item.id}>
    <h3
      className="col-span-8 font-semibold"
      style={{
        fontSize: fontSizeOptions['text-h3'] + fontSize,
      }}
    >
      {item.name}
    </h3>
    <span
      style={{
        fontSize: fontSizeOptions['text-base'] + fontSize,
      }}
    >
      {item.level}
    </span>
  </div>
);

const SkillsF = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const colour = data.metadata.colors.primary;
  return safetyCheck(data.skills) ? (
    <>
      <Heading fontSize={data.metadata.margins.fontSize} iconId="skills">
        {data.skills.heading}
      </Heading>
      {data.skills.items.map((x) => (
        <ResumeTimelineItem
          key={x.id}
          colour={colour}
          margin={data.metadata.margins.lrMargins}
        >
          <SkillItem
            key={x.id}
            item={x}
            fontSize={data.metadata.margins.fontSize}
          />
        </ResumeTimelineItem>
      ))}
    </>
  ) : null;
};

export default memo(SkillsF);
