import React, { memo, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const ObjectiveC = () => {
  const { data } = useContext(PageContext);
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + data.metadata.margins.fontSize,
  });

  return (
    safetyCheck(data.summary, 'body') && (
      <div className="my-8">
        <ReactMarkdown className={classes.root} source={data.summary.body} />
      </div>
    )
  );
};

export default memo(ObjectiveC);
