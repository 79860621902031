import React, { memo } from 'react';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const HeadingH = ({ children, fontSize }) => {
  return (
    <div>
      <h6
        className="px-4 rounded-r leading-loose font-bold tracking-wide"
        style={{
          fontSize: fontSizeOptions['text-h2'] + fontSize,
        }}
      >
        {children}
      </h6>
    </div>
  );
};

export default memo(HeadingH);
