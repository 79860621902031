import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import { LeftHeading } from '../../containers/HeadingModifiers';

const HobbiesE = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize, lrMargins } = data.metadata.margins;

  return safetyCheck(data.hobbies) ? (
    <div className="mb-3">
      <LeftHeading Heading={Heading} fontSize={fontSize} margin={lrMargins}>
        {data.hobbies.heading}
      </LeftHeading>
      <div className="m-4">
        {' '}
        <h6
          className="font-semibold"
          style={{
            fontSize: fontSizeOptions['text-base'] + fontSize,
          }}
        >
          {data.hobbies.items.map((item, index) =>
            index === data.hobbies.items.length - 1
              ? item.name
              : `${item.name}, `,
          )}
        </h6>
      </div>
    </div>
  ) : null;
};

export default memo(HobbiesE);
