import { get } from 'lodash';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';

import PageContext from '../../../../../contexts/PageContext';
import { addressCheck } from '../../../../../utils';
import Icons from '../Icons';

const ContactItem = ({ value, icon, link }) => {
  const { data } = useContext(PageContext);
  const { fontSize } = data.metadata.margins;

  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);

  return value ? (
    <div className="flex items-center">
      <Icon
        size="10px"
        className="mr-2"
        style={{ color: data.metadata.colors.primary, fontSize }}
      />
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="font-medium break-all" style={{ fontSize }}>
            {value}
          </span>
        </a>
      ) : (
        <span className="font-medium break-all" style={{ fontSize }}>
          {value}
        </span>
      )}
    </div>
  ) : null;
};

const ContactA = () => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);

  return (
    <div className="text-xs grid gap-2">
      <ContactItem label={t('shared.forms.phone')} value={data.contact.phone} />
      <ContactItem
        label={t('shared.forms.website')}
        value={data.contact.website}
        link={data.contact.website}
      />
      <ContactItem label={t('shared.forms.email')} value={data.contact.email} />
      <ContactItem
        label={t('shared.forms.linkedIn')}
        value={data.contact.linkedIn}
        link={data.contact.linkedIn}
        icon="linkedIn"
      />

      {data.contact.address && addressCheck(data.contact.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={
            <div>
              <p>{data.contact.address.line1}</p>
              <p>{data.contact.address.line2}</p>
              <p>
                {`${data.contact.address.city} ${data.contact.address.pincode}`}
              </p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default memo(ContactA);
