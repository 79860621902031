import React from 'react';

const A4_HEIGHT = 1131.36;
const VERTICAL_MARGINS = 0;

const makeDivPageHeight = (divRef: React.RefObject<HTMLDivElement>) => {
  const adjust = divRef?.current;
  if (adjust == null) return;
  const { clientHeight } = adjust;
  const numberOfPages = Math.ceil(clientHeight / A4_HEIGHT);
  const newHeight = numberOfPages * A4_HEIGHT;
  if (newHeight > clientHeight) {
    divRef.current.style.height = `${
      numberOfPages * A4_HEIGHT - 2 * numberOfPages * VERTICAL_MARGINS
    }px`;
  } else {
    divRef.current.style.height = `${numberOfPages * A4_HEIGHT}px`;
  }
};

export default makeDivPageHeight;
