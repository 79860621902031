import React, { memo, useContext } from 'react';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';

const HobbiesD = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.hobbies) ? (
    <div className="flex flex-row mt-4">
      <div className="w-1/4">
        <Heading fontSize={data.metadata.margins.fontSize}>
          {data.hobbies.heading}
        </Heading>
      </div>
      <div className="w-3/4 flex flex-col text-left mr-2 mt-3">
        <div className="grid gap-4">
          {data.hobbies.items.map((item) => (
            <h3
              key={item.id}
              className="font-semibold mb-2"
              style={{
                fontSize:
                  fontSizeOptions['text-h3'] + data.metadata.margins.fontSize,
              }}
            >
              {item.name}
            </h3>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default memo(HobbiesD);
