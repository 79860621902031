import React, { useRef, useEffect } from 'react';
import PageContext from '../../../contexts/PageContext';
import ContactH from './blocks/Contact/ContactH';
import EducationD from './blocks/Education/EducationD';
import HeadingH from './blocks/Heading/HeadingH';
import LanguagesD from './blocks/Languages/LanguagesD';
import ObjectiveB from './blocks/Objective/ObjectiveB';
import AwardsE from './blocks/Awards/AwardsE';
import CertificationsE from './blocks/Certifications/CertificationsE';
import HobbiesE from './blocks/Hobbies/HobbiesE';
import SkillsE from './blocks/Skills/SkillsE';
import makeDivPageHeight from '../../../utils/makeDivPageHeight';

import WorkD from './blocks/Work/WorkD';
import styles from './ZetyManagement.module.css';
import fontSizeOptions from '../../../constants/fontSizeOptions';
import ProjectsE from './blocks/Projects/ProjectsE';
import VolunteerD from './blocks/Volunteer/VolunteerD';

import { LeftHeading } from './containers/HeadingModifiers';

const Blocks = {
  summary: ObjectiveB,
  work: WorkD,
  education: EducationD,
  skills: SkillsE,
  languages: LanguagesD,
  awards: AwardsE,
  certifications: CertificationsE,
  hobbies: HobbiesE,
  volunteer: VolunteerD,
  projects: ProjectsE,
};

const ZetyManagement = ({ data }) => {
  const layout = data.metadata.layout.zetymanagement;
  const pageRef = useRef(null);

  useEffect(() => {
    makeDivPageHeight(pageRef);
  }, [pageRef]);

  const Profile = () => (
    <div className="m-4">
      {data.profile.photograph && (
        <img
          className="rounded object-cover m-auto"
          src={data.profile.photograph}
          alt="Resume Photograph"
          style={{ width: '100px', height: '100px' }}
        />
      )}
      <div
        className="font-bold leading-none mt-2"
        style={{
          fontSize: fontSizeOptions['text-h1'] + data.metadata.margins.fontSize,
        }}
      >
        {data.profile.firstName}&nbsp;{data.profile.lastName}
      </div>
      <div
        className="tracking-wide font-medium"
        style={{
          fontSize: fontSizeOptions['text-h2'] + data.metadata.margins.fontSize,
        }}
      >
        {data.profile.subtitle}
      </div>
    </div>
  );

  return (
    <PageContext.Provider value={{ data, heading: HeadingH }}>
      <div
        id="page"
        className="rounded"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          lineHeight: data.metadata.margins.lineHeight,
          fontSize: `${data.metadata.margins.fontSize}px`,
        }}
      >
        <div
          className="grid grid-cols-12"
          style={{ minHeight: '297mm' }}
          ref={pageRef}
        >
          <div className="col-span-4">
            <div
              className="h-full"
              style={{
                backgroundColor: data.metadata.colors.background,
                paddingLeft: `${data.metadata.margins.lrMargins}mm`,
                paddingTop: `${data.metadata.margins.tbMargins}mm`,
                paddingBottom: `${data.metadata.margins.tbMargins}mm`,
              }}
            >
              <div className={styles.left}>
                <Profile />
              </div>
              <LeftHeading
                Heading={HeadingH}
                fontSize={data.metadata.margins.fontSize}
                margin={data.metadata.margins.lrMargins}
              >
                {data.profile.heading}
              </LeftHeading>

              <ContactH />
              {layout[0] &&
                layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>
          <div
            className="col-span-8"
            style={{
              paddingTop: `${data.metadata.margins.tbMargins}mm`,
              paddingBottom: `${data.metadata.margins.tbMargins}mm`,
              paddingRight: `${data.metadata.margins.lrMargins}mm`,
            }}
          >
            <div className="grid gap-4 p-8">
              {layout[1] &&
                layout[1].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default ZetyManagement;
