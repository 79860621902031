import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PageContext from '../../../../../contexts/PageContext';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const ContactItem = ({ value, link, fontSize }) => {
  return value ? (
    <span>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span
            className="font-medium break-all"
            style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
          >
            {value}
            {`  •  `}
          </span>
        </a>
      ) : (
        <span
          className="font-medium break-all"
          style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
        >
          {value}
          {`  •  `}
        </span>
      )}
    </span>
  ) : null;
};

const ContactF = () => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);

  return (
    <div className="flow-root text-center">
      <div>
        {`  •  `}
        <ContactItem
          label={t('shared.forms.phone')}
          value={data.contact.phone}
          fontSize={data.metadata.margins.fontSize}
        />
        <ContactItem
          label={t('shared.forms.email')}
          value={data.contact.email}
          fontSize={data.metadata.margins.fontSize}
        />
        <ContactItem
          label={t('shared.forms.linkedIn')}
          value={data.contact.linkedin}
          link={data.contact.linkedin}
          fontSize={data.metadata.margins.fontSize}
        />
        <ContactItem
          label={t('shared.forms.website')}
          value={data.contact.website}
          link={data.contact.website}
          fontSize={data.metadata.margins.fontSize}
        />
      </div>
      <div
        className="self-end mt-2"
        style={{
          fontSize:
            fontSizeOptions['text-base'] + data.metadata.margins.fontSize,
        }}
      >
        <p>{data.contact.address.line1}</p>
        <p> {data.contact.address.line2}</p>
        <p>
          {data.contact.address.city} {data.contact.address.pincode}
        </p>
      </div>
    </div>
  );
};

export default memo(ContactF);
