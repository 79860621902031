import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';
import { LeftHeading } from '../../containers/HeadingModifiers';

const LanguageItem = (x) => (
  <div key={x.id} className="flex flex-col mb-3">
    <h6 className="font-semibold">{x.name}</h6>
    <span className="text-xs">{x.fluency}</span>
  </div>
);

const LanguagesD = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize, lrMargins } = data.metadata.margins;

  return safetyCheck(data.languages) ? (
    <div>
      <LeftHeading Heading={Heading} fontSize={fontSize} margin={lrMargins}>
        {data.languages.heading}
      </LeftHeading>
      <div className="m-4">{data.languages.items.map(LanguageItem)}</div>
    </div>
  ) : null;
};

export default memo(LanguagesD);
