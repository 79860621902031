import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck, formatDateMMMYYYY } from '../../../../../utils';

const CertificationItem = ({ item, fontSize, key, language, t }) => (
  <div key={key}>
    <h6
      className="font-semibold"
      style={{
        fontSize: fontSizeOptions['text-h3'] + fontSize,
      }}
    >
      {item.title}
    </h6>
    <p>
      {`${item.issuer} ${
        item.date && `(${formatDateMMMYYYY({ date: item.date, language }, t)})`
      }`}
    </p>
    {item.summary && <p className="mt-2">{item.summary}</p>}
  </div>
);

const CertificationsC = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { t } = useTranslation();

  return safetyCheck(data.certifications) ? (
    <div className="flex flex-row mt-4">
      <div className="w-1/4">
        <Heading fontSize={data.metadata.margins.fontSize}>
          {data.certifications.heading}
        </Heading>
      </div>
      <div className="w-3/4 flex flex-col text-left mr-2 mt-3">
        <div className="grid gap-4">
          {data.certifications.items.map((x) => (
            <CertificationItem
              key={x.id}
              item={x}
              language={data.metadata.language}
              fontSize={data.metadata.margins.fontSize}
              t={t}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default memo(CertificationsC);
