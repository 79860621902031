import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';

import PageContext from '../../../contexts/PageContext';
import ContactA from './blocks/Contact/ContactA';
import HeadingB from './blocks/Heading/HeadingB';
import AwardsA from './blocks/Awards/AwardsA';
import CertificationsA from './blocks/Certifications/CertificationsA';
import EducationA from './blocks/Education/EducationA';
import HobbiesA from './blocks/Hobbies/HobbiesA';
import LanguagesA from './blocks/Languages/LanguagesA';
import ProjectsA from './blocks/Projects/ProjectsA';
import SkillsA from './blocks/Skills/SkillsA';
import WorkA from './blocks/Work/WorkA';
import defaultLayouts from '../../../data/defaultLayouts';
import fontSizeOptions from '../../../constants/fontSizeOptions';
import VolunteerA from './blocks/Volunteer/VolunteerA';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const Blocks = {
  work: WorkA,
  education: EducationA,
  projects: ProjectsA,
  awards: AwardsA,
  certifications: CertificationsA,
  skills: SkillsA,
  hobbies: HobbiesA,
  languages: LanguagesA,
  volunteer: VolunteerA,
};

const Pikachu = ({ data }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + data.metadata.margins.fontSize,
  });
  const layout = data.metadata.layout.pikachu
    ? data.metadata.layout.pikachu
    : defaultLayouts.pikachu;

  return (
    <PageContext.Provider value={{ data, heading: HeadingB }}>
      <div
        id="page"
        className="p-8 rounded"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          lineHeight: data.metadata.margins.lineHeight,
          fontSize: `${data.metadata.margins.fontSize}px`,
          paddingLeft: `${data.metadata.margins.lrMargins}mm`,
          paddingRight: `${data.metadata.margins.lrMargins}mm`,
        }}
      >
        <div
          className="grid grid-cols-12 gap-8 content-start"
          style={{ minHeight: '297mm' }}
        >
          <div className="col-span-12">
            <div
              className="h-48 rounded flex flex-row justify-between px-8 py-6"
              style={{
                backgroundColor: data.metadata.colors.primary,
                color: data.metadata.colors.background,
              }}
            >
              <div
                className="flex flex-col flex-grow justify-center w-3/5"
                style={{
                  fontSize:
                    fontSizeOptions['text-base'] +
                    data.metadata.margins.fontSize,
                }}
              >
                <h1
                  className="text-3xl font-bold leading-tight"
                  style={{
                    fontSize:
                      fontSizeOptions['text-h1'] +
                      2 +
                      data.metadata.margins.fontSize,
                  }}
                >
                  {data.profile.firstName} {data.profile.lastName} |{' '}
                  <span
                    className="text-sm font-medium tracking-wide"
                    style={{
                      fontSize:
                        fontSizeOptions['text-h2'] +
                        data.metadata.margins.fontSize,
                    }}
                  >
                    {data.profile.subtitle}
                  </span>
                </h1>

                {data.summary.body && (
                  <div>
                    <hr
                      className="my-5 opacity-25"
                      style={{
                        borderColor: data.metadata.colors.background,
                      }}
                    />

                    <ReactMarkdown
                      className={`${classes.root}`}
                      source={data.summary.body}
                    />
                  </div>
                )}
              </div>
              {data.profile.photograph && (
                <div className="self-center col-span-4">
                  <img
                    className="w-40 h-40 rounded-full mx-auto object-cover"
                    src={data.profile.photograph}
                    alt={data.profile.firstName}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col-span-4">
            <div className="grid gap-4">
              <ContactA />

              {layout[0] &&
                layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>

          <div className="col-span-8">
            <div className="grid gap-4">
              {layout[1] &&
                layout[1].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default Pikachu;
