import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const SkillItem = ({ x, fontSize }) => (
  <div key={x.id} className="flex flex-col">
    <h6
      className="font-semibold"
      style={{
        fontSize: fontSizeOptions['text-base'] + fontSize,
      }}
    >
      {x.name}
    </h6>
    <span
      className="text-xs"
      style={{
        fontSize: fontSizeOptions['text-base'] + fontSize,
      }}
    >
      {x.level}
    </span>
  </div>
);

const SkillsA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize } = data.metadata.margins;

  return safetyCheck(data.skills) ? (
    <div>
      <Heading fontSize={fontSize}>{data.skills.heading}</Heading>
      <div className="grid grid-cols-2 row-gap-2 col-gap-4">
        {data.skills.items.map((item) => (
          <SkillItem x={item} fontSize={fontSize} />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsA);
