import React, { memo, useContext } from 'react';
import { AiFillSafetyCertificate as CertificateIcon } from 'react-icons/ai';
import { BsTools as SkillIcon } from 'react-icons/bs';
import {
  FaAward as AwardIcon,
  FaProjectDiagram as ProjectIcon,
  FaUserFriends as ReferenceIcon,
} from 'react-icons/fa';
import {
  IoLogoGameControllerB as HobbyIcon,
  IoMdBriefcase as WorkIcon,
  IoMdDocument as ObjectiveIcon,
} from 'react-icons/io';
import {
  MdSchool as EducationIcon,
  MdTranslate as LanguageIcon,
} from 'react-icons/md';
import PageContext from '../../../../../contexts/PageContext';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import ResumeTimelineItem from '../../containers/ResumeTimelineItem';

const Icons = {
  summary: <ObjectiveIcon color="white" />,
  work: <WorkIcon color="white" />,
  education: <EducationIcon color="white" />,
  projects: <ProjectIcon color="white" />,
  awards: <AwardIcon color="white" />,
  certifications: <CertificateIcon color="white" />,
  skills: <SkillIcon color="white" />,
  hobbies: <HobbyIcon color="white" />,
  languages: <LanguageIcon color="white" />,
  references: <ReferenceIcon color="white" />,
};

const HeadingI = ({ children, iconId, fontSize }) => {
  const { data } = useContext(PageContext);
  const colour = data.metadata.colors.primary;
  const Icon = Icons[iconId];
  const customFontSize = fontSizeOptions['text-h2'] + fontSize;

  return (
    <ResumeTimelineItem
      icon={Icon}
      colour={colour}
      fontSize={customFontSize}
      margin={data.metadata.margins.lrMargins}
    >
      <h2
        style={{ fontSize: customFontSize, color: colour }}
        className="font-semibold"
      >
        {children}
      </h2>
    </ResumeTimelineItem>
  );
};

export default memo(HeadingI);
