import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const HeadingG = ({ children, fontSize }) => {
  const { data } = useContext(PageContext);

  return (
    <h6
      className="my-2 font-bold pb-1"
      style={{
        color: data.metadata.colors.primary,
        fontSize: fontSizeOptions['text-h2'] + fontSize,
      }}
    >
      {children}
    </h6>
  );
};

export default memo(HeadingG);
