import React, { memo, useContext } from 'react';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck, formatDateMMMYYYY } from '../../../../../utils';

const AwardItem = ({ item, fontSize, key }) => (
  <div key={key} className="flex flex-row">
    <div className="w-1/4">{formatDateMMMYYYY(item.date)}</div>
    <div className="w-3/4 flex flex-col text-left mr-2 ">
      <h6
        className="font-semibold"
        style={{
          fontSize: fontSizeOptions['text-h3'] + fontSize,
        }}
      >
        {item.title}
      </h6>
      <p>{item.awarder}</p>
      {item.summary && <p className="mt-2">{item.summary}</p>}
    </div>
  </div>
);

const AwardsD = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.awards) ? (
    <div>
      <Heading fontSize={data.metadata.margins.fontSize}>
        {data.awards.heading}
      </Heading>

      <div className="grid gap-4">
        {data.awards.items.map((x) => (
          <AwardItem
            key={x.id}
            item={x}
            language={data.metadata.language}
            fontSize={data.metadata.margins.fontSize}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(AwardsD);
