import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import ResumeTimelineItem from '../../containers/ResumeTimelineItem';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const HobbyC = ({ item, fontSize }) => {
  return (
    <div key={item.id}>
      <h3
        className="font-semibold"
        style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
      >
        {item.name}
      </h3>
    </div>
  );
};

const HobbiesC = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const colour = data.metadata.colors.primary;
  return safetyCheck(data.hobbies) ? (
    <>
      <Heading fontSize={data.metadata.margins.fontSize} iconId="hobbies">
        {data.hobbies.heading}
      </Heading>
      {data.hobbies.items.map((x) => (
        <ResumeTimelineItem
          key={x.id}
          colour={colour}
          margin={data.metadata.margins.lrMargins}
        >
          <HobbyC
            key={x.id}
            item={x}
            fontSize={data.metadata.margins.fontSize}
          />
        </ResumeTimelineItem>
      ))}
    </>
  ) : null;
};

export default memo(HobbiesC);
