import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ReactMarkdown from 'react-markdown';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const VolunteerItem = ({ item, language, fontSize }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h3
            className="font-semibold"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {item.link ? <a href={item.link}>{item.title}</a> : item.title}
          </h3>
        </div>
        {item.startDate && (
          <h3
            className="mb-1"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {formatDateRangeMMMYYYY(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h3>
        )}
      </div>
      {item.summary && (
        <ReactMarkdown
          className="markdown mt-2 text-sm"
          source={item.summary}
        />
      )}
    </div>
  );
};

const VolunteerA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize } = data.metadata.margins;

  return safetyCheck(data.volunteer) ? (
    <div>
      <Heading fontSize={fontSize}>{data.volunteer.heading}</Heading>
      <div className="grid gap-4">
        {data.volunteer.items.map((x) => (
          <VolunteerItem
            key={x.id}
            item={x}
            language={data.metadata.language}
            fontSize={fontSize}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(VolunteerA);
