import React, { memo, useContext } from 'react';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';

const SkillItem = ({ item, fontSize }) => (
  <div key={item.id}>
    <h6
      className="font-semibold"
      style={{
        fontSize: fontSizeOptions['text-h3'] + fontSize,
      }}
    >
      {item.name}
    </h6>
    {item.level && <p className="mt-2">{item.level}</p>}
  </div>
);

const SkillsD = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.skills) ? (
    <div className="flex flex-row mt-4">
      <div className="w-1/4">
        <Heading fontSize={data.metadata.margins.fontSize}>
          {data.skills.heading}
        </Heading>
      </div>
      <div className="w-3/4 flex flex-col text-left mr-2 mt-3">
        <div className="grid gap-4">
          {data.skills.items.map((x) => (
            <SkillItem
              key={x.id}
              item={x}
              fontSize={data.metadata.margins.fontSize}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default memo(SkillsD);
