import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const HobbiesA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize } = data.metadata.margins;

  return safetyCheck(data.hobbies) ? (
    <div>
      <Heading fontSize={fontSize}>{data.hobbies.heading}</Heading>
      <h6
        className="font-semibold"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {data.hobbies.items.map((item, index) =>
          index === data.hobbies.items.length - 1
            ? item.name
            : `${item.name}, `,
        )}
      </h6>
    </div>
  ) : null;
};

export default memo(HobbiesA);
