import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PageContext from '../../../contexts/PageContext';
import AwardsB from './blocks/Awards/AwardsB';
import CertificationsB from './blocks/Certifications/CertificationsB';
import ContactF from './blocks/Contact/ContactF';
import EducationB from './blocks/Education/EducationB';
import HeadingF from './blocks/Heading/HeadingF';
import HobbiesB from './blocks/Hobbies/HobbiesB';
import LanguagesC from './blocks/Languages/LanguagesC';
import SkillsC from './blocks/Skills/SkillsC';
import WorkB from './blocks/Work/WorkB';
import ProjectsD from './blocks/Projects/ProjectsD';
import VolunteerB from './blocks/Volunteer/VolunteerB';
import fontSizeOptions from '../../../constants/fontSizeOptions';

const Blocks = {
  education: EducationB,
  work: WorkB,
  projects: ProjectsD,
  volunteer: VolunteerB,
  awards: AwardsB,
  certifications: CertificationsB,
  skills: SkillsC,
  hobbies: HobbiesB,
  languages: LanguagesC,
};

const WallStreet = ({ data }) => {
  const layout = data.metadata.layout.wallStreet;
  const { t } = useTranslation();

  return (
    <PageContext.Provider value={{ data, heading: HeadingF }}>
      <div
        id="page"
        className="p-8 rounded"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          lineHeight: data.metadata.margins.lineHeight,
          fontSize: `${data.metadata.margins.fontSize}px`,
          paddingLeft: `${data.metadata.margins.lrMargins}mm`,
          paddingRight: `${data.metadata.margins.lrMargins}mm`,
          minHeight: '297mm',
        }}
      >
        <div className="w-full flex flex-row">
          <div className="flex flex-col flex-grow justify-center w-3/5">
            <h1
              className="font-bold text-center"
              style={{
                color: data.metadata.colors.primary,
                fontSize:
                  fontSizeOptions['text-h1'] + data.metadata.margins.fontSize,
              }}
            >
              {data.profile.firstName} {data.profile.lastName}
            </h1>

            <ContactF />
          </div>

          {data.profile.photograph && (
            <img
              className="rounded object-cover mr-4"
              src={data.profile.photograph}
              alt="Resume Photograph"
              style={{ width: '100px', height: '100px' }}
            />
          )}
        </div>

        {(data.profile.subtitle || data.summary.body) && (
          <hr
            className="my-3"
            style={{ borderColor: data.metadata.colors.text }}
          />
        )}

        <h2
          className="text-center font-semibold"
          style={{
            fontSize:
              fontSizeOptions['text-h2'] + data.metadata.margins.fontSize,
          }}
        >
          {data.profile.subtitle}
        </h2>
        <p
          className="text-center"
          style={{
            fontSize:
              fontSizeOptions['text-base'] + data.metadata.margins.fontSize,
          }}
        >
          {data.summary.body}
        </p>
        <div className="grid gap-4">
          {layout[0] &&
            layout[0].map((x) => {
              const Component = Blocks[x];
              return Component && <Component key={x} />;
            })}
          <div>
            <HeadingF fontSize={data.metadata.margins.fontSize}>
              {t('builder.templates.additionalInfo')}
            </HeadingF>
            {layout[1] &&
              layout[1].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default memo(WallStreet);
