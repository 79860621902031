import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { addressCheck } from '../../../../../utils';

const ContactItem = ({ value, label, link, fontSize }) => {
  return value ? (
    <div className="flex flex-row">
      <h6
        className="font-bold mr-2"
        style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
      >
        {label}
      </h6>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span
            className="font-normal break-all"
            style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
          >
            {value}
          </span>
        </a>
      ) : (
        <span
          className="font-normal break-all"
          style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
        >
          {value}
        </span>
      )}
    </div>
  ) : null;
};

const ContactG = () => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);

  return (
    <div>
      <div className="relative w-full grid gap-2">
        <ContactItem
          label={t('shared.forms.phone')}
          value={data.contact.phone}
          fontSize={data.metadata.margins.fontSize}
        />
        <ContactItem
          label={t('shared.forms.website')}
          value={data.contact.website}
          link={data.contact.website}
          fontSize={data.metadata.margins.fontSize}
        />
        <ContactItem
          label={t('shared.forms.email')}
          value={data.contact.email}
          fontSize={data.metadata.margins.fontSize}
        />
        <ContactItem
          label={t('shared.forms.linkedIn')}
          value={data.contact.linkedIn}
          link={data.contact.linkedIn}
          fontSize={data.metadata.margins.fontSize}
        />
        {data.contact.address && addressCheck(data.contact.address) && (
          <ContactItem
            label={t('shared.forms.address')}
            value={
              <div>
                <p>{data.contact.address.line1}</p>
                <p>{data.contact.address.line2}</p>
                <p>
                  {`${data.contact.address.city} ${data.contact.address.pincode}`}
                </p>
              </div>
            }
            link={data.contact.linkedIn}
            fontSize={data.metadata.margins.fontSize}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ContactG);
