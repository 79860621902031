import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

const EducationItem = ({ item, language, fontSize }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h3
            className="font-semibold"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {item.institution}
          </h3>
          <h3 style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}>
            <strong>{item.degree}</strong> {item.field}{' '}
            {item.gpa && `, GPA: ${item.gpa}`}
          </h3>
        </div>
        <div
          className="flex flex-col items-end text-right"
          style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
        >
          {item.startDate && (
            <h3 className="mb-1">
              {formatDateRangeMMMYYYY(
                {
                  startDate: item.startDate,
                  endDate: item.endDate,
                  language,
                },
                t,
              )}
            </h3>
          )}
        </div>
      </div>
      {item.summary && (
        <ReactMarkdown
          className={`markdown mt-2 ${classes.root}`}
          source={item.summary}
        />
      )}
    </div>
  );
};

const EducationA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize } = data.metadata.margins;

  return safetyCheck(data.education) ? (
    <div>
      <Heading fontSize={fontSize}>{data.education.heading}</Heading>
      <div className="grid gap-4">
        {data.education.items.map((x) => (
          <EducationItem
            key={x.id}
            item={x}
            language={data.metadata.language}
            fontSize={fontSize}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(EducationA);
