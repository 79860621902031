import React, { memo, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import ResumeTimelineItem from '../../containers/ResumeTimelineItem';
import PageContext from '../../../../../contexts/PageContext';
import { formatDateRangeMMMYYYY, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const useStyles = makeStyles({
  root: {
    fontSize: (props) => props,
  },
});

export const VolunteerItem = ({ item, fontSize }) => {
  const classes = useStyles({
    fontSize: fontSizeOptions['text-base'] + fontSize,
  });

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h3
            className="font-semibold"
            style={{ fontSize: fontSizeOptions['text-h3'] + fontSize }}
          >
            {item.link ? <a href={item.link}>{item.title}</a> : item.title}
          </h3>
        </div>
      </div>
      {item.summary && (
        <ReactMarkdown
          className={`markdown mt-2 ${classes.root}`}
          source={item.summary}
        />
      )}
    </div>
  );
};

const ProjectDate = ({ item, language, fontSize }) => {
  const { t } = useTranslation();

  return (
    <div>
      {item.startDate && (
        <h6 style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}>
          {formatDateRangeMMMYYYY(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
        </h6>
      )}
    </div>
  );
};

const VolunteerE = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const colour = data.metadata.colors.primary;
  const projectDate = (x) => (
    <ProjectDate
      key={x.id}
      item={x}
      language={data.metadata.language}
      fontSize={data.metadata.margins.fontSize}
    />
  );
  return safetyCheck(data.volunteer) ? (
    <>
      <Heading fontSize={data.metadata.margins.fontSize} iconId="volunteer">
        {data.volunteer.heading}
      </Heading>
      {data.volunteer.items.map((x) => (
        <ResumeTimelineItem
          key={x.id}
          leftContent={projectDate(x)}
          colour={colour}
          margin={data.metadata.margins.lrMargins}
        >
          <VolunteerItem
            key={x.id}
            item={x}
            fontSize={data.metadata.margins.fontSize}
          />
        </ResumeTimelineItem>
      ))}
    </>
  ) : null;
};

export default memo(VolunteerE);
