import React from 'react';

import styles from './ResumeTimelineItem.module.css';

const ResumeTimelineItem = ({
  key,
  leftContent,
  children,
  icon,
  colour,
  margin,
}) => {
  return (
    <div className={styles.resumeTimelineItem} key={key}>
      <div
        className={styles.leftContent}
        style={{
          paddingLeft: `${margin}mm`,
        }}
      >
        <div className="pr-4">{leftContent}</div>
      </div>
      <div className={styles.separator}>
        {icon ? (
          <div className={styles.dot} style={{ backgroundColor: colour }}>
            {icon}
          </div>
        ) : (
          <div
            className={styles.smallDot}
            style={{ backgroundColor: colour }}
          />
        )}

        <div className={styles.line} />
      </div>
      <div
        className={styles.rightContent}
        style={{
          paddingRight: `${margin}mm`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ResumeTimelineItem;
