import React, { memo, useContext, useEffect, useState } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { itemCheck, safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';

const SkillsC = () => {
  const { data } = useContext(PageContext);
  const [skills, setSkills] = useState([]);
  const { fontSize } = data.metadata.margins;

  useEffect(() => {
    const temp = [];
    if (!safetyCheck(data.skills)) {
      return;
    }
    for (const item of data.skills.items) {
      if (itemCheck(item.skill)) {
        temp.push(`${item.name} (${item.skill})`);
      } else {
        temp.push(item.name);
      }
    }
    setSkills(temp);
  }, [data.skills.items]);

  return safetyCheck(data.skills) ? (
    <div className="flex flex-row items-start mb-1">
      <div
        className="flex col-span font-bold mr-2"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {`${data.skills.heading}:`}
      </div>
      <div
        className="flex col-span-7"
        style={{
          fontSize: fontSizeOptions['text-base'] + fontSize,
        }}
      >
        {skills.join(', ')}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsC);
