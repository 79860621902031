import React, { memo, useContext } from 'react';
import PageContext from '../../../../../contexts/PageContext';
import { safetyCheck } from '../../../../../utils';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import { LeftHeading } from '../../containers/HeadingModifiers';

const SkillItem = ({ x, fontSize }) => (
  <div key={x.id} className="flex flex-col mb-3">
    <h6
      className="font-semibold"
      style={{
        fontSize: fontSizeOptions['text-base'] + fontSize,
      }}
    >
      {x.name}
    </h6>
    <span
      style={{
        fontSize: fontSizeOptions['text-base'] + fontSize,
      }}
    >
      {x.level}
    </span>
  </div>
);

const SkillsE = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const { fontSize, lrMargins } = data.metadata.margins;

  return safetyCheck(data.skills) ? (
    <div>
      <LeftHeading Heading={Heading} fontSize={fontSize} margin={lrMargins}>
        {data.skills.heading}
      </LeftHeading>
      <div className="m-4">
        {data.skills.items.map((item) => (
          <SkillItem x={item} fontSize={fontSize} />
        ))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsE);
