import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import fontSizeOptions from '../../../../../constants/fontSizeOptions';
import PageContext from '../../../../../contexts/PageContext';
import { addressCheck } from '../../../../../utils';

const ContactItem = ({ value, label, link, fontSize }) => {
  return value ? (
    <div className="mb-2">
      <h4
        className="font-semibold"
        style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
      >
        {label}
      </h4>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span
            className="font-medium break-all"
            style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
          >
            {value}
          </span>
        </a>
      ) : (
        <span
          className="font-medium break-all"
          style={{ fontSize: fontSizeOptions['text-base'] + fontSize }}
        >
          {value}
        </span>
      )}
    </div>
  ) : null;
};

const ContactH = () => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);

  return (
    <div className="m-4">
      <ContactItem
        label={t('shared.forms.phoneAlt')}
        value={data.contact.phone}
        fontSize={data.metadata.margins.fontSize}
      />
      <ContactItem
        label={t('shared.forms.emailAlt')}
        value={data.contact.email}
        fontSize={data.metadata.margins.fontSize}
      />
      <ContactItem
        label={t('shared.forms.linkedIn')}
        value={data.contact.linkedIn}
        link={data.contact.linkedIn}
        fontSize={data.metadata.margins.fontSize}
      />
      <ContactItem
        label={t('shared.forms.website')}
        value={data.contact.website}
        link={data.contact.website}
        fontSize={data.metadata.margins.fontSize}
      />
      {data.contact.address && addressCheck(data.contact.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={
            <div>
              <p>{data.contact.address.line1}</p>
              <p>{data.contact.address.line2}</p>
              <p>
                {`${data.contact.address.city} ${data.contact.address.pincode}`}
              </p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default memo(ContactH);
